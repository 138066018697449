<template>
  <div class="audioForm">
    <form v-on:submit.prevent="saveChanges">
      <div class="form-group">
        <label for="title">Title:</label>
        <input
          type="text"
          v-model="audio.title"
          :class="{ 'form-control': true, 'is-invalid': errors.has('title') }"
          name="title"
          id="title"
          placeholder="Title"
          v-validate="'required'"
        />
        <div class="invalid-feedback">
          Please provide a valid name.
        </div>
      </div>

      <div class="form-group">
        <label for="full_audio_link">Full Audio Link:</label>
        <input
          type="text"
          v-model="audio.full_audio_link"
          :class="{
            'form-control': true,
            'is-invalid': errors.has('full_audio_link')
          }"
          name="full_audio_link"
          id="full_audio_link"
          placeholder=""
          v-validate="'required'"
        />
        <div class="invalid-feedback">
          Please provide a valid full audio link.
        </div>
      </div>

      <div class="form-group">
        <label for="preview_audio_link">Preview Audio Link:</label>
        <input
          type="text"
          @blur="onPreviewAudioLinkChanged"
          v-model="audio.preview_audio_link"
          :class="{
            'form-control': true,
            'is-invalid': errors.has('preview_audio_link')
          }"
          name="preview_audio_link"
          id="preview_audio_link"
          placeholder=""
          v-validate="'required'"
        />
        <div class="invalid-feedback">
          Please provide a valid preview audio link.
        </div>
      </div>

      <div class="form-group">
        <label for="thumbnail_link">Thumbnail Link:</label>
        <input
          type="text"
          v-model="audio.thumbnail_link"
          :class="{
            'form-control': true,
            'is-invalid': errors.has('thumbnail_link')
          }"
          name="thumbnail_link"
          id="thumbnail_link"
          placeholder=""
          v-validate="'required'"
        />
        <img :src="audio.thumbnail_link" alt="Thumbnail Link" />
        <div class="invalid-feedback">
          Please provide a valid thumbnail link.
        </div>
      </div>

      <div class="form-group">
        <label for="store_link">Store Link:</label>
        <input
          type="text"
          v-model="audio.store_link"
          :class="{
            'form-control': true,
            'is-invalid': errors.has('store_link')
          }"
          name="store_link"
          id="store_link"
          placeholder=""
          v-validate="'required'"
        />
        <div class="invalid-feedback">
          Please provide a valid full store link.
        </div>
      </div>

      <fieldset class="form-group">
        <div class="row">
          <legend class="col-form-label col-sm-2 pt-0">Blog Post</legend>
          <div class="col-sm-10">
            <div class="row">
              <label for="blog_title">Title:</label>
              <input
                type="text"
                v-model="audio.blog_post.title"
                :class="{
                  'form-control': true,
                  'is-invalid': errors.has('blog_title')
                }"
                name="blog_title"
                id="blog_title"
                placeholder=""
                v-validate="'required'"
              />
              <div class="invalid-feedback">
                Please provide a valid blog title.
              </div>
            </div>
            <div class="row">
              <label for="blog_link">Link:</label>
              <input
                type="text"
                v-model="audio.blog_post.link"
                :class="{
                  'form-control': true,
                  'is-invalid': errors.has('blog_link')
                }"
                name="blog_link"
                id="blog_link"
                placeholder=""
                v-validate="'required'"
              />
              <div class="invalid-feedback">
                Please provide a valid blog link.
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      <div class="form-group">
        <label for="video">Featured:</label>
        <select class="form-control" name="audio" v-model="audio.featured">
          <option value="0">No</option>
          <option value="1">Yes</option>
        </select>
      </div>

      <div class="form-group">
        <label for="video">Video:</label>
        <select class="form-control" name="video" v-model="audio.video">
          <option
            v-for="video in videos"
            v-bind:value="video.id"
            v-bind:key="video.id"
          >
            {{ video.title }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="description">Description:</label>
        <textarea
          rows="10"
          cols="50"
          v-model="audio.description"
          :class="{
            'form-control': true,
            'is-invalid': errors.has('description')
          }"
          name="description"
          id="description"
          placeholder=""
          v-validate="'required'"
        >
        </textarea>
        <div class="invalid-feedback">
          Please provide a valid description.
        </div>
      </div>

      <div class="form-group">
        <label for="release_date">Release Date:</label>
        <datepicker
          placeholder="Select Date"
          v-model="audio.release_date"
          name="uniquename"
          input-class="form-control"
        ></datepicker>
      </div>

      <div class="form-group signup-carousel-buttons">
        <button class="btn btn-primary">Save</button>
      </div>
    </form>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";

export default {
  methods: {
    saveChanges: function() {
      let token = this.$cookies.get("poolfit_admin_session");

      if (token !== null) {
        let options = {
          headers: { "X-Access-Token": token }
        };
        if (!isNaN(this.$route.params.id)) {
          this.$http
            .patch(
              process.env.VUE_APP_ROOT_API + "/audios/" + this.$route.params.id,
              this.audio,
              options
            )
            .then(response => {
              if (response.status === 200) {
                alert("Record updated successfully");
              }
            });
        } else if (this.$route.params.id == "add") {
          this.$http
            .post(process.env.VUE_APP_ROOT_API + "/audios", this.audio, options)
            .then(response => {
              if (response.status === 200) {
                alert("Record created successfully");
              }
            });
        }
      }
    },

    onPreviewAudioLinkChanged: function() {
      if (this.audio.preview_audio_link.length > 0) {
        var vimeoVideoId = this.audio.preview_audio_link.split("/").pop();

        if (vimeoVideoId) {
          this.$http
            .get(
              "https://vimeo.com/api/oembed.json?url=http%3A//vimeo.com/" +
                vimeoVideoId
            )
            .then(response => {
              var thumbnailLink = response.data.thumbnail_url
                .replace("1280", "560")
                .replace("_640", "_560")
                .replace(".webp", ".jpg");
              this.audio.thumbnail_link = thumbnailLink;
            });
        }
      }
    }
  },

  data: function() {
    return {
      audio: {
        slug: "",
        title: "",
        description: "",
        full_audio_link: "",
        preview_audio_link: "",
        thumbnail_link: "",
        blog_post: {},
        video: 3,
        release_date: "",
        featured: 0
      },

      videos: []
    };
  },

  components: {
    datepicker: Datepicker
  },

  created: function() {
    this.$http.get(process.env.VUE_APP_ROOT_API + "/videos").then(response => {
      this.videos = response.data;
    });

    if (!isNaN(this.$route.params.id)) {
      this.$http
        .get(process.env.VUE_APP_ROOT_API + "/audios/" + this.$route.params.id)
        .then(response => {
          if (!response.data.blog_post) {
            response.data.blog_post = {
              title: "",
              link: ""
            };
          }

          this.audio = response.data;
        });
    } else if (this.$route.params.id !== "add") {
      alert("Invalid parameter.");
    }
  }
};
</script>
<style></style>
